import {
  ALL_MARKETS,
  SUBSCRIPTION_BRIEFING_COPPER,
  SUBSCRIPTION_FORECASTS_COPPER,
  SUBSCRIPTION_SERVICE_COPPER,
  SUBSCRIPTION_FORECASTS_RECYCLING,
  SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
} from '~/constants/services/constants'
import { useAuthorization } from '~/composables/useAuthorization'

const copperSubscriptions = [
  SUBSCRIPTION_FORECASTS_COPPER,
  SUBSCRIPTION_BRIEFING_COPPER,
  SUBSCRIPTION_SERVICE_COPPER,
]

const subscriptionsByMarkets = {
  copper: copperSubscriptions,
  recycling: [
    SUBSCRIPTION_FORECASTS_RECYCLING,
    SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
  ],
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { hasMemberships } = useAuthorization()
  const { marketSlug } = to.meta

  const selectedMarket = ALL_MARKETS.find(
    (market) => market.slug === marketSlug,
  )

  if (!selectedMarket) return

  const marketUnAuthPath = `/${selectedMarket.slug}`
  const premiumMarketPath = `${marketUnAuthPath}/portal`
  const toPath = to.path.replace(/\/$/, '')

  const hasActiveMembership = await hasMemberships(
    subscriptionsByMarkets[selectedMarket.slug],
  )

  if (hasActiveMembership && toPath === premiumMarketPath) return

  if (hasActiveMembership) {
    return navigateTo(premiumMarketPath, { redirectCode: 302 })
  }
})
